<template>
  <div id="lx-box-m">
    <div class="lx" v-if="showlianxiang && list.length">
      <div
        class="lx-item"
        v-for="(item, index) in list"
        :key="item.id"
        @click="clickme(item, index)"
      >
        <div class="lx-item-title">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHelp } from "@/server/api";

export default {
  data() {
    return {
      showlianxiang: false, //隐藏联想框
      list: [], //搜索结果项
    };
  },
  methods: {
    getData(param) {
      this.showlianxiang = true;
      if (param) {
        this.getLx(param);
      }
      // if (param === '') {
      //   if (JSON.parse(sessionStorage.getItem('hotProblem'))) {
      //     this.list = JSON.parse(sessionStorage.getItem('hotProblem'))
      //   }else{
      //     this.getHot(1)
      //   }
      // }else{
      //   this.getLx(param)
      // }
    },

    //获取hot问题联想
    getHot(param) {
      let data = {
        isHotspot: param,
      };

      getHelp(data).then((res) => {
        if (res.code == 200 && res.data) {
          this.list = res.data.filter((e) => {
            return e.piece === 3;
          });
          sessionStorage.setItem("hotProblem", JSON.stringify(res.data));
        }
      });
    },
    //获取输入值联想
    getLx(param) {
      let data = {
        title: param,
      };

      getHelp(data).then((res) => {
        if (res.code == 200 && res.data) {
          this.list = res.data.filter((e) => {
            return e.piece === 3;
          });
        }
      });
    },
    //点击问题出详情
    clickme(val, index) {
      this.$parent.$refs.comp.clickme2(val);
      this.showlianxiang = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import './helpLianxiang.css';
</style>
