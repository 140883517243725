<template>
  <div id="helpright-m">
    <div class="content" v-if="!isAnswer">
      <el-collapse accordion v-model="activeName">
        <el-collapse-item :title="val.name" name="1">
          <div class="content-item" v-for="(item, index) in answerVal" :key="index">
            <div class="content-item-title" @click="clickme(item, index)">
              <span>·</span>
              <div class="list">
                {{ item.title }}
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="学生使用帮助" name="2">
          <el-collapse accordion v-model="activeName2">
            <el-collapse-item class="navigation" title="系统操作" name="1">
              <div class="content-item" v-for="(item, index) in studentHelpOne" :key="index">
                <div class="content-item-title" @click="clickme(item, index)">
                  <span>·</span>
                  <div class="list">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item class="navigation" title="其他" name="2">
              <div class="content-item" v-for="(item, index) in studentHelpTwo" :key="index">
                <div class="content-item-title" @click="clickme(item, index)">
                  <span>·</span>
                  <div class="list">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- 答案层 遮罩 -->
    <div class="answer" v-else>
      <div class="firstline">
        <div class="back" @click="isAnswer = false">
          <img :src="fontAssetsURL(`mobile/help/左_深色.png`)" alt="" />
        </div>
        <!-- <div class="close" @click="isAnswer=false">x</div> -->
        <div class="answer-title">{{ answerValitem.title }}</div>
      </div>

      <!-- <div class="answer-info">
        <div class="answer-info-date">
          发布时间：2021-01-27 17:33:33
        </div>
        <div class="answer-info-count">
          查看数：268
        </div>
      </div> -->
      <div class="answer-hengxian"></div>
      <div class="ql-editor answer-content" v-html="answerValitem.answer"></div>
    </div>
  </div>
</template>

<script>
import { getHelp } from "@/server/api";

export default {
  data() {
    return {
      activeName: "1",
      activeName2: "1",
      val: {}, //val:{id:0,title:'xxx',ctx:['problem1','problem2','problem3'...]}
      currentitem: 0, //右侧list当前项
      isAnswer: false, //回答框
      answerVal: {}, //二级标题下的问题title
      answerValitem: {}, //问题的answer
      show: false, //二级菜单下的问题块
      studentHelpOne: {}, //学生使用帮助 系统操作问题
      studentHelpTwo: {}, //学生使用帮助 其他问题
    };
  },
  created() {},
  methods: {
    //获取学生使用帮助下的系统操作问题
    line2List(val) {
      getHelp({
        piece: 3,
        port: Number(val[0].children[0].nodeId.slice(-1)), //1：系统操作  3：其他
      }).then((res) => {
        if (res.code == 200 && res.data) {
          this.studentHelpOne = res.data;
        }
      });
      // 获取学生使用帮助下的其他问题
      getHelp({
        piece: 3,
        port: Number(val[0].children[1].nodeId.slice(-1)), //1：系统操作  3：其他
      }).then((res) => {
        if (res.code == 200 && res.data) {
          this.studentHelpTwo = res.data;
        }
      });
    },
    //接受父传递值
    getData(params) {
      if (Array.isArray(params) || params === "hot") {
        this.val = {
          name: "热门问题",
        };
        if (localStorage.getItem("hotProblem")) {
          this.answerVal = JSON.parse(localStorage.getItem("hotProblem"));
        } else {
          this.getHot();
        }
      } else {
        this.val = params;
        this.getQita(params);
      }
    },
    //获取hot问题
    getHot() {
      let data = {
        isHotspot: 1,
      };
      // 1.获取热门问题
      getHelp(data).then((res) => {
        if (res.code == 200 && res.data) {
          this.answerVal = res.data.filter((p) => {
            return p.piece === 3;
          });
          if (!localStorage.getItem("hotProblem")) {
            localStorage.setItem("hotProblem", JSON.stringify(this.answerVal));
          }
        }
      });
    },

    //关闭回答框
    closeanswer() {
      if (this.isAnswer) {
        this.isAnswer = false;
      }
      if (this.show) {
        this.show = false;
      }
    },
    //问题选择
    clickme(val, idx) {
      this.currentitem = idx;
      this.isAnswer = true;
      this.answerValitem = val;
    },
    //联想问题选择
    clickme2(val) {
      this.isAnswer = true;
      this.answerValitem = val;
      // console.log(val)
      // this.$parent.lianxiangVal = val.title
      // this.$parent.$refs.help.showlianxiang = false
    },
  },
};
</script>

<style scoped>
@import "../../style/all.css";
</style>

<style lang="stylus" scoped>
@import './helpComp.css';
</style>
